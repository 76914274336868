@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CMontserrat%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CLato%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&#038;display=auto&#038;ver=6.5.5');

body {
	margin: 0;
	padding: 0;
	font-family: 'Open Sans', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	input:-webkit-autofill {
		background-color: transparent !important;
		-webkit-box-shadow: 0 0 0 50px white inset !important;
	}

	.dark input:-webkit-autofill {
		background-color: transparent !important;
		-webkit-box-shadow: 0 0 0 50px #313131 inset !important;
		-webkit-text-fill-color: white !important;
	}
}

@layer base {
	h5 {
		@apply absolute px-6 duration-200 w-52 bottom-4 md:bottom-8 md:px-10 group-hover:scale-110 group-hover:text-black;
	}
}

/* @layer components {
  .btn {
    @apply px-10 py-2 my-0 font-bold tracking-widest uppercase border-2 border-black  hover:bg-black hover:text-white;
  }
} */

.item-container {
	@apply flex flex-col justify-between w-full space-y-6 text-2xl text-white uppercase md:flex-row md:space-y-0 md:space-x-8;
}

.item {
	@apply relative overflow-hidden md:w-1/4;
}

.item-gradient {
	@apply absolute top-0 bottom-0 right-0 left-0 bg-gradient-to-b from-transparent to-gray-900 group-hover:from-gray-50 group-hover:to-white group-hover:opacity-70;
}

/* #hero {
  background-image: url('../images/desktop/image-hero.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 576px) {
  #hero {
    background-image: url('../images/mobile/image-hero.jpg');
    background-position: center;
  }
} */

/* Hamburger Menu */
.hamburger {
	cursor: pointer;
	width: 24px;
	height: 24px;
	transition: all 0.25s;
	position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
	position: absolute;
	width: 24px;
	height: 2px;
	top: 0;
	left: 0;
	transform: rotate(0);
	transition: all 0.5s;
}

.hamburger-middle {
	transform: translateY(7px);
}

.hamburger-bottom {
	transform: translateY(14px);
}

.open {
	transform: rotate(90deg);
}

.open .hamburger-top {
	transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
	display: none;
}

.open .hamburger-bottom {
	transform: rotate(-45deg) translateY(6px) translateX(-6px);
}
